import IconSvg from "components/IconSvg";

type Props = {
  effect: string;
  height?: string;
  width?: string;
  className?: string;
};

const EffectIcon: React.FC<Props> = ({
  effect,
  height = "20",
  width = "20",
  className = "",
}) => {
  const effectFileName = effect.toLowerCase().replace(" ", "-");

  return (
    <IconSvg
      height={height}
      width={width}
      className={className}
      filePath={`effects/${effectFileName}.svg`}
    />
  );
};

export default EffectIcon;
