// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
export const getTargetedData = (data: any, userState: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  let items = data.filter((item: any) =>
    item.targeting?.states_or_provinces?.find(
      (state: string) => state.trim() === userState,
    ),
  );

  if (!items.length) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    items = data?.filter((item: any) => {
      const states = item.targeting?.states_or_provinces?.filter(Boolean);
      return !states?.length;
    });
  }

  return items;
};
