import { trackEvent } from "@leafly-com/web-utils";

import { ads } from "constants/ads";

import GoogleAd from "components/GoogleAd";
import TrackImpression from "components/TrackImpression";

import SectionWrapper from "../SectionWrapper";

const FeaturedBrands = ({ className = "" }) => (
  <TrackImpression
    category="Featured Brands Carousel"
    label="Featured Brands Carousel"
    data-testid="featured-brands-section"
    className={className}
    id="div-gpt-ad-brands" // this is required for adblock to function correctly
  >
    <SectionWrapper
      className="container"
      title="Here’s a deal worth your attention"
      changeLocation={false}
      onClickEvents={() => {
        trackEvent(
          "Carousel",
          "Click",
          "home featured brands carousel show all",
        );
      }}
    >
      <div className="shadow-low rounded m-xs overflow-hidden min-h-[288px] mt-md">
        <GoogleAd
          adPath={ads.halfTakeover.path}
          targeting={{
            section: "homepage",
          }}
        />
      </div>
    </SectionWrapper>
  </TrackImpression>
);

export default FeaturedBrands;
