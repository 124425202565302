import React from "react";

type Props = {
  sectionName: string;
  children?: React.ReactNode;
};

const NoResults: React.FC<Props> = ({ sectionName, children }) => (
  <div
    className="bg-leafly-white py-section px-xxl border border-light-grey w-full"
    data-testid={`${sectionName}-section-error`}
  >
    <div className="text-center mx-auto -mb-sm" style={{ maxWidth: 600 }}>
      {children}
    </div>
  </div>
);

export default NoResults;
