import { useEffect, useState } from "react";
import { trackEvent } from "@leafly-com/web-utils";
import { useSelector } from "react-redux";

import { HomePageNewsSection } from "custom-types/Directus";
import { getState } from "redux/selectors/location";
import { getTargetedData } from "utils/getTargetedData";

import Button from "components/botanic/Button";
import Image from "components/Image";
import Placeholder, { Rect } from "components/Placeholder";

type Props = {
  newsSections: HomePageNewsSection<string>[];
  className?: string;
};

const HomePageNewsCta = ({ newsSections, className }: Props) => {
  const [isContentLoading, setIsContentLoading] = useState(true);
  const [newsSection, setNewsSection] = useState<HomePageNewsSection<string>>();

  const userState = useSelector(getState);

  const getNewsSection = () => {
    const newsSectionItems = getTargetedData(newsSections, userState);
    const newsSectionItem = newsSectionItems?.[0];

    if (newsSectionItem) {
      setNewsSection(newsSectionItem);
    }

    setIsContentLoading(false);
  };

  useEffect(() => {
    getNewsSection();
  }, []);

  useEffect(() => {
    if (newsSection?.description) {
      const links = document.querySelectorAll<HTMLAnchorElement>(
        "#news-section-desc a",
      );

      const onDescLinkClick = (e: Event) => {
        trackEvent(
          "Homepage News",
          "Click",
          (e.currentTarget as HTMLAnchorElement).text,
        );
      };

      links?.length &&
        links.forEach((link) => {
          if (link.addEventListener) {
            link.addEventListener("click", onDescLinkClick);
          }
        });

      return () => {
        links?.length &&
          links.forEach((link) => {
            link.removeEventListener("click", onDescLinkClick);
          });
      };
    }
  }, [newsSection]);

  return newsSection && !isContentLoading ? (
    <div className={className}>
      <div className="container block">
        <div className="md:flex items-center">
          {newsSection.image && (
            <div
              style={{ minWidth: 320, width: 320 }}
              className="mx-auto md:mx-section"
            >
              <Image
                src={newsSection.image}
                alt={`${newsSection.title} image`}
                sizes={[320]}
                ratio="square"
              />
            </div>
          )}
          <div className="mx-xl">
            {newsSection.title && (
              <h2 className="mb-sm mt-xl md:mt-none">{newsSection.title}</h2>
            )}
            {newsSection.description && (
              <div
                id="news-section-desc"
                className="mb-lg"
                dangerouslySetInnerHTML={{ __html: newsSection.description }}
              />
            )}
            {newsSection.button_link && newsSection.button_text && (
              <Button
                href={newsSection.button_link}
                onClick={() => {
                  trackEvent("Homepage News", "Click", newsSection.button_text);
                }}
              >
                {newsSection.button_text}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : !newsSection && !isContentLoading ? (
    <></>
  ) : (
    <div className={className}>
      <div className="container block">
        <div className="md:flex items-center">
          <div className="mx-auto md:mx-section">
            <Placeholder
              width="320"
              height="320"
              className="block w-full"
              style={{ minWidth: 320 }}
            >
              <Rect width="320" height="320" />
            </Placeholder>
          </div>
          <div className="mx-xl w-full">
            <Placeholder
              width="90%"
              height="32"
              className="mb-sm mt-xl md:mt-none"
            >
              <Rect width="100%" height="100%" />
            </Placeholder>
            <Placeholder width="100%" height="72" className="mb-lg">
              <Rect width="100%" height="100%" />
            </Placeholder>
            <Placeholder width="320" height="48">
              <Rect width="100%" height="100%" rx="24" ry="24" />
            </Placeholder>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageNewsCta;
