import { trackEvent } from "@leafly-com/web-utils";

import Button from "components/botanic/Button";
import Image from "components/Image";

const SocialImpact = ({ className = "" }) => (
  <div className={className}>
    <div className="container relative">
      <h2 className="mb-sm">Social Impact on Leafly</h2>
      <div
        className="carousel-card--gradient relative flex items-end rounded overflow-hidden"
        style={{ height: 400 }}
      >
        <div className="relative text-white m-xl md:m-section z-10">
          <p className="heading--xl mb-lg md:mb-xs">
            The cannabis industry is ripe with opportunity...for some.
          </p>
          <p className="hidden md:block mb-lg">
            We're a long way from equity in cannabis. At Leafly, we're on a
            mission to strengthen the industry. Want to join forces, get
            informed, get involved?
          </p>
          <Button
            className="inline-block min-w-[160px]"
            href="/social-impact"
            onClick={() => {
              trackEvent("Homepage Campaign", "Click", "Social Justice");
            }}
          >
            start here
          </Button>
        </div>
        <Image
          alt="Social impacts in the cannabis industry"
          background={true}
          sizes={[512, 735, 992, null, null, 767]}
          src={`https://leafly-directus.imgix.net/originals/a97adfaa-7baf-4549-beea-2e986cf9ea1b.jpg`}
        />
      </div>
    </div>
  </div>
);

export default SocialImpact;
