import { stringify } from "qs";

import consumerApi from "api/services/consumer-api";
import { Coordinates } from "custom-types/Coordinates";
import { ApiResponse, SearchPageTypes } from "custom-types/Search";
import {
  DEFAULT_SEARCH_SKIP,
  DEFAULT_SEARCH_TAKE,
} from "redux/reducers/search";
import formatImgixUrl from "utils/image/formatImgixUrl";
import logError from "utils/logError";

type Query = {
  [key: string]: string | string[] | undefined;
};

const getSearchResults = async (
  query: Query,
  coordinates: Coordinates,
  maxDistance: number,
  countryCode = "US",
  take = DEFAULT_SEARCH_TAKE,
  skip = DEFAULT_SEARCH_SKIP,
  boost = "",
  skip_aggs = false,
) => {
  const route = "/api/search/v1";

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  const params: any = {
    ...query,
    ...coordinates,

    filter: {
      all_strains: true,
    },

    skip,

    skip_aggs,
    // TODO: do we want to include max_distance?
    // "filter[max_distance]": maxDistance || 30,
    take,
  };
  if (boost) {
    params.boost = boost;
  } // don't send null boost to consumer-api

  const body = stringify(params, {
    arrayFormat: "brackets",
    encodeValuesOnly: true,
  });

  try {
    const { data } = await consumerApi.get<ApiResponse>(`${route}?${body}`, {
      headers: {
        "x-country-code": countryCode,
      },
    });

    const {
      hits: {
        strain,
        brand,
        dispensary,
        article,
        menuItem,
        menuItemDispensary,
      },
      metadata: { availableFilters, relevanceOrder, totalCount },
    } = data;

    strain?.length &&
      strain.forEach((_strain, i) => {
        strain[i].nugImage = formatImgixUrl(strain[i].nugImage || "");
      });

    const mappedFilters = availableFilters.map((filter) => ({
      ...filter,
      values: filter.values.map((value) => value.value),
    }));

    const menuItemCategories =
      availableFilters
        .find((filter) => filter.name === "product_category")
        ?.values?.map((values) => values.value) || [];

    const menuItemDispensaries = menuItemDispensary?.slice(0, 4);

    // Return sorted categories that have results
    // Brand and article are manually sorted last
    const categoryRelevanceOrder = Object.entries(relevanceOrder)
      .filter(
        ([key, value]) =>
          value && ["dispensary", "strain", "menuItem"].includes(key),
      )
      .sort(([, aValue], [, bValue]) => bValue - aValue)
      .map(([relevanceType]) => relevanceType) as SearchPageTypes[];
    if (brand?.length) {
      categoryRelevanceOrder.push("brand");
    }
    if (article?.length) {
      categoryRelevanceOrder.push("article");
    }

    return {
      article: article || [],
      availableFilters: mappedFilters,
      brand: brand || [],
      categoryRelevanceOrder,
      dispensary: dispensary || [],
      menuItem: menuItem || [],

      // TODO: send this back a different way?
      menuItemCategories,

      menuItemDispensaries: menuItemDispensaries || [],
      metadata: { totalCount: totalCount },
      strain: strain || [],
    };
  } catch (e) {
    logError(e.message, {
      functionName: "getSearchResults",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return {
      article: [],
      availableFilters: [],
      brand: [],
      categoryRelevanceOrder: [],
      dispensary: [],
      menuItem: [],
      menuItemCategories: [],
      menuItemDispensaries: [],
      metadata: { totalCount: 0 },
      strain: [],
    };
  }
};

export default getSearchResults;
