import { Coordinates } from "custom-types/Coordinates";
import { Strain } from "custom-types/Strain";
import formatImgixUrl from "utils/image/formatImgixUrl";
import logError from "utils/logError";

import { getAvailableMenuItems } from "./getAvailableMenuItems";
import getSearchResults from "./getSearchResults";

const getStrainsWithAvailability = async (
  coordinates: Coordinates,
  countryCode: string,
  effects: string[],
) => {
  try {
    const strains: Strain[] = [];

    await Promise.all(
      effects.map(async (effect) => {
        try {
          const filter = {
            "filter[top_effect]": effect,
            "filter[type]": "strain",
          };

          await getSearchResults(
            filter,
            coordinates,
            300,
            countryCode,
            1,
            0,
            "",
            true,
          ).then((res) => {
            if (res?.strain?.length) {
              strains.push(res?.strain[0]);
            }
          });
        } catch {
          logError(
            "Error processing data in getStrainsWithAvailability effects map",
          );
        }
      }),
    );

    if (strains.length) {
      strains.forEach((strain, i) => {
        if (strain.nugImage) {
          strains[i].nugImage = formatImgixUrl(strain.nugImage);
        }
      });

      const strainNames = strains.map((strain) => strain.name);

      const availabilities = await getAvailableMenuItems(
        coordinates,
        strainNames,
      );

      if (availabilities.length) {
        const strainsWithAvailability = strains.map((strain) => {
          const strainAvailability = availabilities.filter(
            (availability: { name: string }) =>
              availability.name === strain.name,
          );
          const hasMenuItems = !!(
            strainAvailability &&
            strainAvailability[0] &&
            strainAvailability[0].menuItemCategories &&
            strainAvailability[0].menuItemCategories.length
          );
          return { ...strain, hasAvailability: hasMenuItems };
        });

        return {
          strains: strainsWithAvailability,
        };
      } else {
        return {
          strains,
        };
      }
    } else {
      return {
        strains: [],
      };
    }
  } catch (err) {
    logError("Error processing data in getStrainsWithAvailability");

    return { strains: [] };
  }
};

export default getStrainsWithAvailability;
