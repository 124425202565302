import consumerApi from "api/services/consumer-api";
import { Coordinates } from "custom-types/Coordinates";
import logError from "utils/logError";

export const getAvailableMenuItems = async (
  coordinates: Coordinates,
  strainNames: string[],
  maxDistance = 30,
) => {
  try {
    const { data } = await consumerApi.get(`/api/strains/v1/available_facets`, {
      params: {
        max_distance: maxDistance,
        strain_names: strainNames,
        ...coordinates,
      },
    });

    return data;
  } catch (e) {
    logError(e.message, {
      functionName: "getAvailableMenuItems",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return [];
  }
};
