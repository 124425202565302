import React from "react";
import classNames from "classnames";
import Link from "next/link";

interface StarRatingProps {
  /**
   * Default element is a div. Provide an `as` prop to use a different element.
   */
  as?: React.ElementType;
  rating?: number;
  ratingCount?: number;
  ratingCountClass?: string;
  /**
   * Whether or not the rating should be displayed as a number next to the stars.
   */
  showRating?: boolean;
  starLink?: string;
  starLinkTracking?: () => void;
  /**
   * The desired width of the stars, in pixels.
   */
  starWidth?: number;
  /**
   *  Toggles between the star rating redesign on the strain pg & the design used on the rest of the site
   */
  showRedesign?: boolean;
  className?: string;
  /**
   *  Includes 'reviews' text on star rating
   */
  showReviewsText?: boolean;
}

const ConditionalWrapper = ({
  starLink,
  wrapper,
  children,
}: {
  starLink: string | undefined;
  wrapper: (children: React.ReactNode) => React.ReactNode;
  children: React.ReactNode;
}) => <>{starLink ? wrapper(children) : children}</>;

const StarRating: React.FC<StarRatingProps> = ({
  as: Element = "div",
  showRating = true,
  starWidth = 12,
  rating = 0,
  className,
  ratingCount,
  ratingCountClass,
  showRedesign,
  starLink,
  starLinkTracking,
  showReviewsText,
  ...others
}) => {
  const classes = classNames("text-xs", className);

  const width = `${+(rating / 5).toPrecision(1) * 100}%`;
  const label = `Rating: ${rating.toFixed(1)} out of 5 stars${
    ratingCount ? ` based on ${ratingCount} reviews` : ""
  }`;
  const ratingCountDisplay =
    ratingCount &&
    (ratingCount > 9999
      ? `${(ratingCount / 1000).toFixed(1)}k`
      : ratingCount.toLocaleString("en-us"));

  return (
    <ConditionalWrapper
      starLink={starLink}
      wrapper={(children: React.ReactNode) => (
        <>
          <Link href={starLink || ""} onClick={starLinkTracking}>
            {children}
          </Link>
        </>
      )}
    >
      <Element className={classes} role="img" aria-label={label} {...others}>
        {/**
         * The ARIA role "img" should treat this as a presentational element
         * and not read the contents, but the MDN docs note that there is some
         * variation in browser implementation. This additional span should
         * ensure that the text content is not read aloud.
         *   See: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Role_Img#using_roleimg_to_confer_meaning_that_is_obscured_or_implied
         *   Reference implementation: https://codepen.io/svinkle/pen/oYjoNE
         **/}
        <span
          className={classNames({
            "flex items-center": !showRedesign,
          })}
          aria-hidden="true"
        >
          {showRating && (
            <span
              className={classNames("pr-xs", {
                "text-lg block mb-xs ml-xs": showRedesign,
              })}
            >
              {rating.toFixed(1)}
            </span>
          )}
          <span
            className={classNames("star-rating star-rating--dark", {
              "block ml-xs mb-xs": showRedesign,
            })}
            style={
              {
                "--star-percentage": width,
                "--star-width": `${starWidth}px`,
              } as React.CSSProperties
            }
            {...others}
          />
          {!!ratingCount && (
            <span
              className={classNames("pl-xs pb-[3px", {
                "text-sm": showRedesign,
              })}
            >
              (
              <span
                className={classNames(ratingCountClass, {
                  underline: starLink,
                })}
              >
                {ratingCountDisplay}
                {showRedesign && " ratings"}
                {showReviewsText && " reviews"}
              </span>
              )
            </span>
          )}
        </span>
      </Element>
    </ConditionalWrapper>
  );
};

export default StarRating;
