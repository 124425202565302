import React from "react";
import classNames from "classnames";

import { ValueProps as ValuePropsType } from "custom-types/Directus";

import {
  Circle,
  Diamond,
  Hex,
  PillLeft,
  PillRight,
  Triangle,
} from "./PropShapes";

type Props = ValuePropsType & { className?: string };

const ValueProps = ({
  section_title,
  section_subtitle,
  header_1,
  description_1,
  header_2,
  description_2,
  header_3,
  description_3,
  className,
}: Props) => {
  return (
    <div className={classNames("relative", className)}>
      <Triangle />
      <Diamond />
      <Circle />
      <Hex />
      <div className="container relative">
        <PillLeft />
        {section_title && (
          <h2 className="text-center text-xxl mb-sm">{section_title}</h2>
        )}
        {section_subtitle && (
          <p className="text-center text-md mb-xl">{section_subtitle} </p>
        )}
        <div className="flex items-center justify-center text-center mb-xxl row">
          <div className="mb-lg lg:mb-none col md:col-1/3 w-full ">
            <p className="font-extrabold text-md mb-xs">{header_1}</p>
            <p>{description_1}</p>
          </div>
          <div className="mb-lg lg:mb-none col md:col-1/3 w-full ">
            <p className="font-extrabold text-md mb-xs">{header_2}</p>
            <p>{description_2}</p>
          </div>
          <div className="col md:col-1/3 w-full ">
            <p className="font-extrabold text-md mb-xs">{header_3}</p>
            <p>{description_3}</p>
          </div>
        </div>
        <PillRight />
      </div>
    </div>
  );
};

export default ValueProps;
