import React from "react";
import classNames from "classnames";
import Link from "next/link";

import Image from "components/Image";
import { Sizes } from "components/Image/Image";

type Props = {
  className?: string;
  disableLazyLoad?: boolean;
  /**
   * Image url for product category
   */
  image: string;
  /**
   * Image size array for image component
   */
  sizes?: Sizes;
  /**
   * Product category title
   */
  title: string;
  /**
   * classes for title element
   */
  titleClassName?: string;

  /**
   * size for title element, xs || sm(default) || md
   */
  titleSize?: string;
  /**
   * Product category url
   */
  url: string;
  /**
   * This is on a page that is using coins as a filter (i.e. menu page)
   */
  filterable?: boolean;
  onClick?: () => void;
};

const ProductCategoryCard: React.FC<Props> = ({
  className,
  disableLazyLoad = false,
  image,
  sizes = [100],
  title,
  titleClassName,
  titleSize = "sm",
  url,
  filterable,
  onClick,
}) => {
  const titleClasses = classNames(
    "font-bold text-green",
    { "text-md": titleSize === "md" },
    { "text-sm": titleSize === "sm" },
    { "text-xs": titleSize === "xs" },
    titleClassName,
  );

  const coinUrl = filterable ? "" : url;

  return (
    <Link
      href={coinUrl}
      className={classNames(className, "block text-center")}
      onClick={onClick}
      data-testid={`${title}-products-category`}
    >
      {/* New stacking context with isolation for iOS browsers overflow bug */}
      <div
        className="rounded-full shadow-low overflow-hidden mb-lg"
        style={{ height: "80px", isolation: "isolate", width: "80px" }}
      >
        <Image
          src={image}
          sizes={sizes}
          alt={`${title} product example image`}
          ratio="square"
          disableLazyLoad={disableLazyLoad}
        />
      </div>
      <div className={titleClasses}>{title.toLowerCase()}</div>
    </Link>
  );
};

export default ProductCategoryCard;
