import { Cannabinoids } from "custom-types/Strain";

export const sortTopCannabs = (cannabinoids: Cannabinoids) => {
  if (typeof cannabinoids !== "object") {
    return [];
  }

  const cannabinoidsArray = Object.entries(cannabinoids).map((e) => e[1]); // converts object of objects into an array of objects
  const sorted = cannabinoidsArray
    .filter((cannab) => cannab.percentile50 && cannab.percentile50 > 0) // filter out cannabs with percentile50 0 or null
    .sort((a, b) => ((a.percentile50 || 0) > (b.percentile50 || 0) ? -1 : 1)); // sort highest to lowest

  if (
    sorted.length < 2 &&
    !sorted.find((cannab) => cannab.displayName === "THC")
  ) {
    const thcCannab = cannabinoidsArray.find(
      (cannab) => cannab.displayName === "THC",
    );
    if (thcCannab) {
      sorted.push(thcCannab);
    }
  }

  if (
    sorted.length < 2 &&
    !sorted.find((cannab) => cannab.displayName === "CBD")
  ) {
    const cbdCannab = cannabinoidsArray.find(
      (cannab) => cannab.displayName === "CBD",
    );
    if (cbdCannab) {
      sorted.push(cbdCannab);
    }
  }

  return sorted;
};
