import React, { useState } from "react";
import { trackEvent } from "@leafly-com/web-utils";
import Link from "next/link";
import { useSelector } from "react-redux";

import getStrainsWithAvailability from "api/requests/getStrainsWithAvailability";
import { Strain } from "custom-types/Strain";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import useOnComponentInView from "hooks/useOnComponentInView";
import {
  getCity,
  getCoordinates,
  getFormattedLocation,
  getState,
} from "redux/selectors/location";

import FeaturedStrainCard from "components/FeaturedStrainCard";

const effects = ["Happy", "Euphoric", "Aroused"];

const FeaturedStrains = ({ className = "" }) => {
  const [strains, setStrains] = useState<Strain[]>([]);

  const countryCode = useDomainCountryCode();
  const coordinates = useSelector(getCoordinates);
  const formattedLocation = useSelector(getFormattedLocation);
  const city = useSelector(getCity);
  const state = useSelector(getState);

  const getStrains = async () => {
    const { strains } = await getStrainsWithAvailability(
      coordinates,
      countryCode,
      effects,
    );
    setStrains(strains);
  };

  const [ref] = useOnComponentInView(getStrains, {
    triggerOnce: true,
  });

  return (
    <div ref={ref} data-testid="strain-focus" className={className}>
      <div className="container">
        <h2 className="text-center mb-sm lg:mb-none">
          Great weed you can find today.
        </h2>
        <p className="text-center lg:mb-xxl">
          These are all found near
          <Link
            href={`/dispensaries/${state
              ?.replace(/\s+/g, "-")
              .toLowerCase()}/${city?.replace(/\s+/g, "-").toLowerCase()}`}
            className="font-bold pointer text-green underline ml-xs"
            onClick={() => {
              trackEvent("Homepage Strains", "Click", "Dispensaries near you");
            }}
          >
            {formattedLocation}
          </Link>
        </p>
        <div className="flex row">
          {effects.map((effect, i) => {
            const strain = strains.filter(
              (strain) => strain.topEffect === effect,
            )[0];
            return (
              <FeaturedStrainCard
                strain={strain}
                lineBreak={i + 1 !== strains.length}
                effect={effect}
                key={i}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FeaturedStrains;
