import React, { useId } from "react";
import ContentLoader from "react-content-loader";

const FeaturedStrainCardPlaceholder: React.FC = () => {
  const uniqueKey = useId();
  return (
    <ContentLoader
      speed={2}
      width={300}
      height={426}
      viewBox="0 0 300 426"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      uniqueKey={uniqueKey}
    >
      <rect x="20" y="364" rx="14" ry="14" width="260" height="28" />
      <rect x="41" y="4" rx="0" ry="0" width="208" height="208" />
      <rect x="10" y="228" rx="0" ry="0" width="280" height="28" />
      <rect x="75" y="264" rx="0" ry="0" width="150" height="20" />
      <rect x="88" y="292" rx="0" ry="0" width="126" height="20" />
      <rect x="38" y="328" rx="0" ry="0" width="64" height="20" />
      <rect x="118" y="328" rx="0" ry="0" width="65" height="20" />
      <rect x="198" y="328" rx="0" ry="0" width="65" height="20" />
    </ContentLoader>
  );
};

export default FeaturedStrainCardPlaceholder;
