import { trackEvent } from "@leafly-com/web-utils";
import cx from "classnames";
import Link from "next/link";

import { CampaignSlots } from "custom-types/Directus";

import Image from "components/Image";
import TrackImpression from "components/TrackImpression";

const CampaignCard = ({
  title,
  subtitle,
  cta_for_button,
  link_url,
  image,
  className,
}: Omit<CampaignSlots<string>, "targeting"> & {
  className: string;
}) => (
  <TrackImpression
    category="Homepage Campaign"
    label={title}
    className={cx("relative", className)}
  >
    <Link
      className="carousel-card--gradient block relative flex items-end rounded overflow-hidden h-full"
      href={link_url || ""}
      style={{ minHeight: 290 }}
      onClick={() => {
        trackEvent("Homepage Campaign", "Click", title);
      }}
    >
      <div className="relative text-white m-xl md:m-xxl z-10">
        <p className="heading--xl mb-lg md:mb-xs">{title}</p>
        <p className="hidden md:block">{subtitle}</p>
        <div className="button inline-block" style={{ minWidth: 160 }}>
          {cta_for_button}
        </div>
      </div>
      {image && (
        <Image
          alt={`${title} image`}
          background={true}
          sizes={[512, 735, 362, 502, null, 767]} // need to edit once this is in directus for situations where only one shows up
          src={image}
        />
      )}
    </Link>
  </TrackImpression>
);

export default CampaignCard;
