interface Ads {
  [index: string]: { path: string; id?: string };
}

export const ads: Ads = {
  halfTakeover: {
    id: `div-gpt-ad-1614288008013-0`,
    path: `/13212770/home/half_takeover`,
  },
  searchLower: {
    id: `div-gpt-ad-1614288333655-0`,
    path: `/13212770/search/lower`,
  },
  searchUpper: {
    id: `div-gpt-ad-1614288137196-0`,
    path: `/13212770/search/upper`,
  },
  strainPhotosLower: {
    path: `/13212770/strain/photos_lower`,
  },
  strainPhotosUpper: {
    path: `/13212770/strain/photos_upper`,
  },
};
