import React from "react";

import Placeholder, { Rect } from "components/Placeholder";

const ProductCategoryCardPlaceholder: React.FC<{
  className?: string;
  key?: string;
  style?: React.CSSProperties;
}> = (props) => (
  <div {...props}>
    <div className="rounded-full shadow-low overflow-hidden mb-lg">
      <Placeholder width="80" height="80">
        <Rect width="100%" height="100%" />
      </Placeholder>
    </div>
    <Placeholder width="90" height="20" className="mx-auto">
      <Rect width="100%" height="100%" />
    </Placeholder>
  </div>
);

export default ProductCategoryCardPlaceholder;
