import { trackEvent, trackNonInteractionEvent } from "@leafly-com/web-utils";

import { Action, Category } from "constants/events";

type EventParams = {
  action?: string;
  origination: string;
  id: number;
  retailType: string;
  tier: number;
  mapMarkerLevel?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  customDimensions?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  trackingFunction?: any;
  label: string;
};

export const formatAction = (action: string) =>
  action.replace(/[^A-Za-z0-9]/g, "");

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
export const flushMerchId = (customDimensions: any) => ({
  ...customDimensions,
  merchandisingCampaignId:
    customDimensions?.merchandisingCampaignId || undefined,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
export const getMerchId = (store: any, unitNameToFind: string) => {
  const merchCampaign = store.merchandisedCampaigns?.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    (campaign: any) => campaign?.unitName === unitNameToFind,
  );
  return merchCampaign?.id;
};

export const getEventLabel = (
  origination: string,
  tier: number,
  mapMarkerLevel?: string | null,
) => {
  if (origination === "Map Spotlight") {
    return origination;
  }

  if (tier < 200) {
    if (origination === "Map Marker") {
      return "map marker - platinum";
    }

    return `platinum placement - ${origination.toLowerCase()}`;
  }

  if (mapMarkerLevel === "custom") {
    return `custom list`;
  }
};

export const trackStoreEvent = ({
  action,
  origination,
  id,
  retailType,
  tier,
  mapMarkerLevel,
  customDimensions,
  trackingFunction = trackNonInteractionEvent,
}: EventParams) => {
  const formattedLabel = getEventLabel(origination, tier, mapMarkerLevel);
  if (!formattedLabel) {
    return null;
  }
  if (!customDimensions?.merchandisingCampaignId) {
    customDimensions = {
      ...customDimensions,
      merchandisingCampaignId: undefined,
    };
  }

  return trackingFunction(Category.finder, action, formattedLabel, {
    dispensaryId: id,
    retailType: retailType,
    ...flushMerchId(customDimensions),
  });
};

export const trackStoreClick = ({
  label,
  origination,
  id,
  retailType,
  tier,
  mapMarkerLevel,
  customDimensions,
}: EventParams) => {
  trackStoreEvent({
    action: Action.click,
    customDimensions: flushMerchId(customDimensions),
    id,
    label,
    mapMarkerLevel,
    origination,
    retailType,
    tier,
    trackingFunction: trackEvent,
  });
};

export const trackStoreImpression = ({
  label,
  origination,
  id,
  retailType,
  tier,
  mapMarkerLevel,
  customDimensions,
}: EventParams) => {
  trackStoreEvent({
    action: Action.impression,
    customDimensions: flushMerchId(customDimensions),
    id,
    label,
    mapMarkerLevel,
    origination,
    retailType,
    tier,
    trackingFunction: trackNonInteractionEvent,
  });
};
