import React from "react";

import PillIcon from "components/Icons/cannabinoids/cbc.svg";
import CircleIcon from "components/Icons/cannabinoids/cbd.svg";
import TriangleIcon from "components/Icons/cannabinoids/cbg.svg";
import DiamondIcon from "components/Icons/cannabinoids/thc.svg";
import HexIcon from "components/Icons/cannabinoids/thcv.svg";

const SHARED_CLASSES = "border-green-20 text-white absolute";
const ICON_PROPS = { className: "text-green-20" };

export const PillLeft = () => (
  <div className={SHARED_CLASSES} style={{ marginLeft: -100 }}>
    <PillIcon height="60" {...ICON_PROPS} />
  </div>
);

export const PillRight = () => (
  <div
    className={`${SHARED_CLASSES} bottom-0 right-0 -mb-section hidden md:block`}
  >
    <PillIcon height="60" {...ICON_PROPS} />
  </div>
);

export const Triangle = () => (
  <div className={`${SHARED_CLASSES} pt-section`}>
    <TriangleIcon width="75" height="75" {...ICON_PROPS} />
  </div>
);

export const Diamond = () => (
  <div className={`${SHARED_CLASSES} bottom-0`}>
    <DiamondIcon width="91" height="105" {...ICON_PROPS} />
  </div>
);

export const Hex = () => (
  <div className={`${SHARED_CLASSES} right-0`}>
    <HexIcon width="82" height="120" {...ICON_PROPS} />
  </div>
);

export const Circle = () => (
  <div className={`${SHARED_CLASSES} right-0 bottom-0 mb-section`}>
    <CircleIcon width="53" height="53" {...ICON_PROPS} />
  </div>
);
